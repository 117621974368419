header {
    height: 100vh;
    padding-top: 7rem;
    overflow: hidden;
}

.header__container {
    text-align: center;
    height: 100%;
    position: relative;
}

/* ================================= CTA ================================ */

.cta {
    margin-top: 2.5rem;
    margin-left: 2rem;
    margin-bottom: 2rem;
    display: flex;
    gap: 1.3rem;
    justify-content: center;
}

/* ================================= Header Socials ================================ */
.header__socials {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.9rem;
    position: absolute;
    left: 0;
    bottom: 25rem;
}

.header__socials::after{
   content: '';
   width: 1px;
   height: 2rem;
   background-color: var(--color-primary); 
}

/* ================================= ME ================================ */
.me{
    background: linear-gradient(var(--color-primary), transparent);
    width:22rem;
    height: 35rem;
    position: absolute;
    left: calc(50% - 11rem);
    margin-top: 4rem;
    border-radius: 12rem 12rem 0 0;
    overflow:hidden;
    padding: 5rem 1.5rem 1.5rem 1.5rem;
}

/* ============================= Scroll Down ================================ */
.scroll__down {
    position: absolute;
    right: -2.3rem;
    bottom: 27rem;
    transform: rotate(90deg);
    font-weight: 300;
    font-size: 1rem;
}

/* ===================================== Media Queries (Medium Devices) ======================================= */

@media screen and (max-width: 1024px) {
    header{
        height: 100vh;
    }   

}


/* ========================================== Media Queries (Small Devices) ==================================== */

@media screen and (max-width: 600px) {
    header{
        height: 100vh;
    }
    .header__socials, .scroll__down {
        display:none;
    }
}